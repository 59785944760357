/* eslint-disable @typescript-eslint/default-param-last */
import * as actionTypes from '../actions/actionTypes';

const initalState: any = { isLoading: false };

const uiReducer = (state: any = initalState, action: any) => {
  switch (action.type) {
    case actionTypes.UI_START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.UI_STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default uiReducer;
